<template>
  <div class="co-flex-col">
    <template v-if="imgCount === 1">
      <v-row>
        <v-col
          cols="12"
        >
          <v-img
            :src="thumbs[0]"
            :aspect-ratio="ratio"
            height="inherit"
            class="rounded"
            @click="onClick"
          />
          <!-- <v-img
            :src="thumbs[0]"
            max-height="500"
            max-width="500"
            contain
            @click="onClick"
          /> -->
        </v-col>
      </v-row>

      <!-- <v-card
        flat
        color="white"
        class="rounded"
      >
        <v-img
          :src="thumbs[0]"
          :aspect-ratio="ratio"
          contain
          class="rounded"
          @click="onClick"
        />
      </v-card> -->
    </template>
    <template v-else-if="imgCount === 2">
      <v-row>
        <v-col
          v-for="(src, index) in thumbs"
          :key="index"
          cols="12"
          md="6"
        >
          <v-img
            class="rounded"
            :src="src"
            :aspect-ratio="ratio"
            height="inherit"
            @click="onClick"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="imgCount > 2">
      <v-row>
        <v-col
          v-for="(src, index) in thumbs"
          :key="index"
          cols="12"
          md="4"
        >
          <v-img
            class="rounded"
            :src="src"
            :aspect-ratio="ratio"
            height="inherit"
            @click="onClick"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-img
        v-if="item.cover"
        :src="item.cover"
        :aspect-ratio="ratio"
        height="inherit"
        class="rounded"
        @click="onClick"
      />
    </template>
  </div>
</template>

<script>
  export default {
    name: 'ContentImages',
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      ratio: {
        type: Number,
        default: 1.5
      }
    },
    data () {
      return {
        thumbs: [],
        imgCount: 0,
      }
    },
    watch: {
      item: {
        handler (val) {
          this.thumbs = val.thumbs || []
          this.imgCount = this.thumbs.length
        },
        immediate: true
      }
    },
    methods: {
      onClick (ev) {
        this.$emit('click', this.item)
      }
    }
  }
</script>
